import * as React from 'react'
import { graphql } from 'gatsby';

import GeneralLayout from '../components/layouts/general'
import { blockHeaderResolver, blockResolve } from '../helpers';

const BlogEntryPage = (props: any) => {
  const { data } = props;
  const { title, publishDate, category, body, tags, metaTags, metaDescription, navBarColor, navBarStickyColor } = data.page;


  React.useEffect(() => {
    console.log('firing this one');
    (window as any).page && (window as any).page.init();
  }, [])

  return (
  <GeneralLayout
    htmlTitle={title}
    metaDescription={metaDescription?.metaDescription}
    metaTags={metaTags}
    navBarColor={navBarColor}
    navBarStickyColor={navBarStickyColor}
  >

<div className="section">
        <div className="container">

          <div className="text-center mt-8">
            <h2>{title}</h2>
            <p>{publishDate} in <a href="#">{category}</a></p>
          </div>

          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div dangerouslySetInnerHTML={{__html: body.childMarkdownRemark.html }}></div>
              <div className="gap-xy-2 mt-6">
                { tags?.map((t: any) => <a className="badge badge-pill badge-secondary" href="#">{t}</a>)}
                
              </div>

            </div>
          </div>


        </div>
      </div>

      {/* <LiveDemoForm image={data.dash1} /> */}
  </GeneralLayout>
  );
}


export default BlogEntryPage

export const pageQuery = graphql`
  query BlogEntryPage ($slug: String){
    page: contentfulBlogEntries (slug: {eq: $slug}) {
      id slug 
      title
      category
      body { childMarkdownRemark {
        html
      } }
      tags
      publishDate,
    }
  }
`